<template>
  <div class="traffic">
    <div class="nav">
      <Carousel show="1" />
    </div>
    <div class="navs">
      <div class="navcon">
        <p class="nav_p">
          <span
            :style="{
              background: show == 'exhibition' ? '#197CC3' : '#fff',
              color: show == 'exhibition' ? '#fff' : '#000',
              zIndex: show == 'exhibition' ? '5' : '',
            }"
            @click="gopage('exhibition', $event, 'ExhibitionHallSurvey')"
            >{{ $t("language.ExhibitionHallSurvey") }} <b>|</b>
          </span>
          <span
            :style="{
              background: show == 'site' ? '#197CC3' : '#fff',
              color: show == 'site' ? '#fff' : '#000',
              zIndex: show == 'site' ? '5' : '',
            }"
            @click="gopage('site', $event, 'exhibitionGround')"
            >{{ $t("language.exhibitionGround") }} <b>|</b></span
          >
          <span
            :style="{
              background: show == 'conference' ? '#197CC3' : '#fff',
              color: show == 'conference' ? '#fff' : '#000',
              zIndex: show == 'conference' ? '5' : '',
            }"
            @click="gopage('conference', $event, 'ConferenceCenter')"
            >{{ $t("language.ConferenceCenter") }} <b>|</b></span
          >
          <span
            :style="{
              background: show == 'survey' ? '#197CC3' : '#fff',
              color: show == 'survey' ? '#fff' : '#000',
              zIndex: show == 'survey' ? '5' : '',
            }"
            @click="gopage('survey', $event, 'ExquisiteGroupPictures')"
            >{{ $t("language.ExquisiteGroupPictures") }}</span
          >
        </p>
        <p>
          <van-icon color="#999999" name="wap-home" size="20" />
          <span
            >{{ $t("language.current") }} : {{ $t("language.HOMEpage") }}
            >
            <span
              v-html="$t('language.PavilionGuide')"
              class="spans"
            ></span>
            > {{ $t(title) }}
          </span>
        </p>
      </div>
    </div>
    <div class="content" v-if="show == 'exhibition'">
      <Exhibition />
    </div>
    <div class="content" v-else-if="show == 'site'">
      <Site />
    </div>
    <div class="content" v-else-if="show == 'conference'">
      <Conference />
    </div>
    <div class="content" v-else-if="show == 'survey'">
      <Survey />
    </div>
    <div class="bot">
      <Bottom />
    </div>
  </div>
</template>

<script>
import Conference from "../../components/gallery/conference.vue";
import Exhibition from "../../components/gallery/exhibition.vue";
import Site from "../../components/gallery/site.vue";
import Survey from "../../components/gallery/survey.vue";
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
export default {
  components: {
    Carousel,
    Bottom,
    Conference,
    Exhibition,
    Site,
    Survey,
  },
  data() {
    return {
      show: "",
      title: "展馆概况",
    };
  },
  created() {
    this.showcomponent();
  },
  mounted() {
    let traffic = document.getElementsByClassName("traffic")[0];
    this.$nextTick(() => {
      setTimeout(() => {
        let targetbox = document.getElementsByClassName("navs")[0];
        traffic.scrollTop = targetbox.offsetTop;
      });
    });
  },
  methods: {
    showcomponent() {
      if (this.$route.query.path == "展馆概况") {
        this.show = "exhibition";
        this.title = "language.ExhibitionHallSurvey";
      } else if (this.$route.query.path == "展览场地") {
        this.show = "site";
        this.title = "language.exhibitionGround";
      } else if (this.$route.query.path == "会议中心") {
        this.show = "conference";
        this.title = "language.ConferenceCenter";
      } else if (this.$route.query.path == "精美组图") {
        this.show = "survey";
        this.title = "language.ExquisiteGroupPictures";
      } else if (this.$route.query.path == "/") {
        this.show = "exhibition";
      } else if (this.$route.query.path == "") {
        this.show = "exhibition";
      }
    },
    gopage(val, e, title) {
      if (val == "exhibition") {
        this.$router.replace({
          path: "/exhibition",
          query: { path: `展馆概况` },
        });
      } else if (val == "site") {
        this.$router.replace({
          path: "/exhibition",
          query: { path: `展览场地` },
        });
      } else if (val == "conference") {
        this.$router.replace({
          path: "/exhibition",
          query: { path: `会议中心` },
        });
      } else if (val == "survey") {
        this.$router.replace({
          path: "/exhibition",
          query: { path: `精美组图` },
        });
      }
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
      this.title = "language." + title;
    },
  },
};
</script>

<style scoped>
.spans >>> span {
  font-size: 15px !important;
}
.traffic {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.bot {
  width: 100%;
}
.content {
  width: 100%;
  background: #fafafa;
}
.traffic::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.nav {
  width: 100%;
  height: 100%;
}
.navcon {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.navs {
  width: 1400px;
  margin: 0 auto;
}
.navcon p:nth-of-type(2) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navconright {
  line-height: 71px;
}
.navcon p {
  font-size: 15px;
  display: inline-block;
  line-height: 70px;
}
.nav_p :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.navcon p span {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.nav_p :hover b {
  display: none !important;
}
.nav_p span {
  position: relative;
}
.nav_p span b {
  position: absolute;
  z-index: 2 !important;
  right: -4px;
}
.navcon p:nth-of-type(1) {
  height: 100%;
  float: left;
}
.navcon p:nth-of-type(1) span {
  padding: 0 10px;
  float: left;
  text-align: center;
}
/* .navcon p:nth-of-type(1) span:nth-last-of-type(1) {
  border-right: 0;
} */
</style>