<template>
   <div class="introduce">
      <div class="main_top">
        <div class="introduce_top">
          <div class="citybank">
             {{ $t("language.Cityshoreseaofcloud") }}
          </div>
          <div class="introduce_left">
            <h1>
                {{ $t("language.ExhibitionCenter") }}
                <!-- 烟台八角湾国际会展中心 -->
            </h1>
            <span>
                {{ $t("language.expl") }}
              <!-- 烟台八角湾国际会展中心位于烟台开发区八角街道,占地209.27亩,建筑面积20万平方米,
              总投资约32亿元,由美国著名设计公司
              AECOM根据第五代会展综合体定位设计,以“城岸
              云浪海上银贝”为设计理念,将烟台的碧海与蓝天相接,海浪与白云相交融,再融入轻盈的银
              贝最终演化出建筑结构与造型相互统一、结构清晰的,集展览区、会议区和配套商业区为一
              体的会展商业体 -->
            </span>
          </div>
          <div class="introduce_right">
            <img src="../../assets/image/会展中心.png" alt="" />
          </div>
        </div>
      </div>
      <div class="main_conter">
        <div class="introduce_conter">
          <div class="citybank_two">
             {{ $t("language.Theexhibitionarea") }} 
          </div>
          <div class="conter_left">
            <img
              src="../../assets/image/20210304烟台八角湾会展中心方案文本 (2)_页面_058.png"
              alt=""
            />
          </div>
          <div class="conter_right">
            <h5>
                {{ $t("language.YantaiBajiao") }}
              <!-- 烟台八角湾国际会展中心位于烟台开发区八角街道,占地209.27亩,建筑面积20万平方米,
              总投资约32亿元。 -->
            </h5>
            <span>
                {{ $t("language.Theexhibition") }}
              <!-- 会展中心展览区分为室内展区和室外展区,室内共设有8个展厅,室内展览面积6.6
              万平方米,总展位3300个;室外展览面积约3.3万平方米,可为各类会展活动提供
              展览空间。 -->
            </span>
          </div>
        </div>
        <!-- <div class="exhibition">
          <img src="../../assets/image/展厅.png" alt="" />
        </div> -->
        <div class="exhibition">
        <ul class="uul">
          <li><span class="spann">8 <sup>个</sup></span><p class="size">展厅</p></li>
          <li><span class="spann">56000 <sup>平方米</sup></span><p class="size">室内展览面积</p></li>
          <li><span class="spann">2800 <sup>个</sup></span><p class="size">展位</p></li>
          <li><span class="spann">33000 <sup>平方米</sup></span><p class="size">室外展览面积</p></li>
        </ul>
      </div>
      </div>
      <div class="main_bottom">
        <div class="introduce_bottom">
          <div class="citybank_three">
             {{ $t("language.Assemblyarea") }} 
          </div>
          <div class="bottom_left">
            <h5>
                {{ $t("language.Theconference") }}
              <!-- 会展中心会议区共5层、面积3万平方米,根据需求设有不同规模的会议室共计30
              个,总计可容纳3600余人,可承接各类会议、宴会、论坛讲座、企业年会等大型室
              内活动 -->
            </h5>
            <span>
                {{ $t("language.Inaddition") }}
              <!-- 此外,会展中心整体设计采用环保先进的建筑技术及智能系统,配备有智能停车技术
              的室内外停车位1800余个;2500平方米的配套厨房可为参展商、会议等人员提供
              多种形式的美食享受。 -->
            </span>
          </div>
          <div class="bottom_right">
            <img src="../../assets/image/会议中心2层前厅.png" alt="" />
          </div>
        </div>
      </div>
      <div class="conference">
        <ul class="uul">
          <li><span class="spann">5 <sup>层</sup></span><p class="size">会议中心</p></li>
          <li><span class="spann">30000 <sup>平方米</sup></span><p class="size">总面积</p></li>
          <li><span class="spann">30 <sup>个</sup></span><p class="size">会议室</p></li>
          <li><span class="spann">3600 <sup>余人</sup></span><p class="size">可容纳</p></li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.introduce {
  width: 100%;
  height: 2336px;
  position: relative;
}
.main_top {
  width: 100%;
  background-color: #fafafa;
}
.introduce_top {
  width: 1400px;
  margin: 0 auto;
}
.introduce_top {
  margin: 0 auto;
  padding-top: 96px;
  height: 589px;
}
.introduce_left {
  float: left;
  padding-top: 112px;
  width: 660px;
  height: 461px;
  position: relative;

}
.citybank{
width: 600px;
height: 149px;
font-size: 150px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #F0EFEF;
line-height: 100px;
position: absolute;
right:1000px;
top:108px;
}
.citybank_two{
  width: 600px;
height: 149px;
font-size: 150px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #F0EFEF;
line-height: 100px;
position: absolute;
left:1000px;
top:90px;
}
.citybank_three{
  width: 600px;
height: 149px;
font-size: 150px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #F0EFEF;
line-height: 100px;
position: absolute;
bottom:590px;
right: 1000px;
}
.introduce_left h1 {
  font-size: 30px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #333333;
line-height: 30px;
  display: block;
  position: absolute;
  z-index: 5;
}
.introduce_left span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
    margin-top: 76px;
  display: inline-block;
  width: 660px;
  height: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical; 
  /* -webkit-line-clamp:2;  */
  overflow-x: hidden;

}
.introduce_left :hover{
  overflow: auto;
}
.introduce_right {
  float: right;
}
.main_conter {
  width: 100%;
  height: 772px;
  background-color: #ffffff;
  position: relative;
}
.exhibition {
  width: 1039px;
  height: 146px;
  background-color: #197CC3;
  opacity: 0.9;
  position: absolute;
  top: 305px;
  left: 624px;
}
.introduce_conter {
  width: 1400px;
  margin: 0 auto;
}
.cheched {
  background-color: #1a7cc3;
  color: white;
}
.introduce_conter {
  padding-top: 70px;
  height: 772px;
}
.conter_right {
  margin-left: 30px;
  float: left;
  width: 650px;
  height: 557px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
}
.conter_left {
  float: left;
  width: 700px;
}
.conter_right h5 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
  font-weight: normal;
  display:block;
  position: absolute;
  z-index: 5;
  bottom: 350px;
}
.conter_right span {
 font-size: 18px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 40px;
  display: block;
  position: absolute;
  bottom: 30px;

}
.main_bottom {
  padding-top: 127px;
  width: 100%;
  height: 749px;
  background-color: #fafafa;
  
}
.introduce_bottom {
  width: 1400px;
  margin: 0 auto;
}
.bottom_left {
  width: 656px;
  height: 545px;
  float: left;
  margin-right:40px;
    display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
}
.bottom_left h5 {
  font-size: 18px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 40px;
  font-weight: normal;
  display: block;
  position: absolute;
  bottom: 320px;

}
.bottom_left span {
 font-size: 18px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 40px;
  display: block;
  position: absolute;
  top:380px;
}
.bottom_right {
  float: left;
}
.conference {
  width: 1039px;
  height: 146px;
  background-color: #197CC3;
  opacity: 0.9;
  position: absolute;
  bottom: 370px;
  left: 277px;
}
.uul{
  width: 1039px;
  height:146px ;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.uul li{
  width: auto;
  height: 120px;
}
.spann{
  font-size: 70px;
  font-weight: 550;
  color: white;
}
sup{
  font-size: 20px;
  font-weight: 500;
}
.size{
  padding-left: 5px;
  font-size: 20px;
  /* font-weight: bold; */
  color: white;
}
</style>