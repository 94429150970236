<template>
   <div class="introduce">
            <div class="view">
                <div :class="{banner:true,trans:flag}"  @mouseenter="enter" @mouseleave="leave">
                    <img  :src="arr[imgindex]" alt="" class="img">
                </div>
<swiper class="swiper" :options="swiperOption">
    <swiper-slide class="swiper-slide swiper-container-3d" v-for='(item,index) in arr'  :key="index">

    <img :src="item" alt="" class="imgs" style="width:200px;height:112px;margin-top: 20px;margin-left: 50px;" @click='select(index)'>
      <div class="swiper-pagination" slot="pagination"></div>
      </swiper-slide>
</swiper>
 
 <div class="swiper-button-prev" slot="button-prev"></div>
 <div class="swiper-button-next" slot="button-next"></div>
                <button @click="prev" class="prev"  @mouseenter="enter" @mouseleave="leave"> ＜ </button>
                <button @click="next" class="next"  @mouseenter="enter" @mouseleave="leave">＞</button>
            </div>
      </div>
</template>

<script>
export default {
    data() {
        return {
            swiperOption: {
                slidesPerView: 4, // 每页展示几条数据
                slidesPerGroup: 4, // 每屏滚动几条数据
                autoplay: false, // 是否自动播放
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: ''
                },
                observer: true,
                observeParents: true,
            },
            mobile:window.sessionStorage.getItem("mobile"),
                imgindex:'0',//控制变量，默认显示第一张
                flag:true,
                timer:null,
            arr:[],
             height:"calc(100% - 70px)"
        }
    },
    created() {
        let data = {
            }
        this.$api.imgs(data,res=>{ 
                let arr = res.data.data.map((item)=>{
                    item=item.picUrl
                    return item
                })
                this.arr=arr
        }) 
        this.timer=setInterval(this.next.bind(this),2000)
      //还可以使用箭头函数就不用bind
        // this.timer=serInterval(()=>{
        // this.next()
        // },2000)
    },
    methods:{
        scroll(){
        this.height = "100%"
    },
          // 鼠标移入view
           enter:function(){
            clearInterval(this.timer);
        },
            leave:function(){
            this.timer=setInterval(this.next.bind(this),2000)
        },
       //下一张
      next:function(){
          if(this.imgindex==this.arr.length-1){
              this.imgindex=0;
          }else{
              this.imgindex++;
          }
      },
      //上一张
      prev:function(){
          if(this.imgindex==0){
              this.imgindex=this.arr.length-1
          }else{
              this.imgindex--
          }
      },
          select(index){
            this.imgindex=index
        },
    
    }
}
</script>

<style scoped>
.introduce{
        width:1190px;
        height: 950px;
        margin: 0 auto;
        padding-top: 144px;
    }
    .cheched{
        background-color: #1a7cc3;
        color: white;
    }

        img{
            float:left  
        }
        .banner{
            width: 1190px;
            height: 659px;
            overflow: hidden;
        }
        .trans{
            transition:all .5s
        }
        .view{
            width: 1190px;
            height: 950px;
            position: relative;
            overflow: hidden;
        }
        img{
            width: 1190px;
            height: 659px;
        }
        .prev{
            position: absolute;
            left: 32px;
            top:320px ;
            width: 60px;
            height: 60px;
            background: #000000;
            opacity: 0.4;
            border-radius: 50%;
            border: none;
            color: #FFFFFF;
        }
        .next{
            position: absolute;
            right: 32px;
            top: 320px;
            width: 60px;
            height: 60px;
            background: #000000;
            opacity: 0.4;
            border-radius: 50%;
            border: none;
            color: #FFFFFF;
        }
        .swiper-button-prev, .swiper-button-next{
            top:77%
        }
</style>