<template>
      <div class="introduce">
           <div class="introduce_top">

              </div>
          <div class="introduce_main">
             
              <div class="main_top" v-for="(v,i) in arr" :key="i">
                  <h5>{{$t(v.title)}}</h5>
                  <p>{{$t(v.text)}}</p>
                  <img :src="v.url" alt="">
                  <img :src="v.url2" alt="" v-if="v.url2">
                  <img :src="v.url3" alt="" v-if="v.url3" class="imgbot">
                  <img :src="v.url4" alt="" v-if="v.url4" class="imgbot">
              </div>
          </div>
            <div class="introduce_tab">
                <div class="tab_title">
                    <p><span></span>
                    {{$t('language.Centertechnicaldata')}}
                    <!-- 会议中心技术数据 -->
                    </p>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th rowspan="2" style="background:#0F6FB4;color:#FFF">
                                {{$t('language.conferenceroom')}}
                                <!-- 会议室 -->
                            </th>
                            <th rowspan="2" style="background:#197BC2;color:#FFF">
                                 {{$t('language.msquared')}}
                                <!-- 面积（m²） -->
                            </th>
                            <th rowspan="2" style="background:#1D84D0;color:#FFF">
                                 {{$t('language.Lengthwidthheight')}}
                                <!-- 长*宽*高（m） -->
                            </th>
                            <th colspan="4" style="background:#258CD9;color:#FFF;border-bottom:1px solid #FFFFFF;">
                                 {{$t('language.seatingcapacity')}}
                                <!-- 可容纳人数 -->
                            </th>
                            
                        </tr>
                        <tr class="thspan">
                            <th style="background:#258CD9;color:#FFF">
                                 {{$t('language.classstyle')}}
                                <!-- 课桌式 -->
                            </th>
                            <th style="background:#258CD9;color:#FFF">
                                 {{$t('language.theaterstyle')}}
                                <!-- 剧院式 -->
                            </th>
                            <th style="background:#258CD9;color:#FFF">
                                 {{$t('language.converseU')}}
                                <!-- u字型 -->
                            </th>
                            <th style="background:#258CD9;color:#FFF;" class="thspan_right">
                                 {{$t('language.feast')}}
                                <!-- 宴会 -->
                            </th>
                        </tr>
                        <tr class="trs" v-for="(item,index) in tablelsit" :key="index">
                            <td>{{item.meetName}}</td>
                            <td>{{item.area}}</td>
                            <td>{{item.lengthWidthHeight}}</td>
                            <td>{{item.desk}}</td>
                            <td>{{item.theatre}}</td>
                            <td>{{item.uType}}</td>
                            <td>{{item.banquet}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="introduce_bottom">

            </div>
        </div>
</template>

<script>
export default {
  data() {
        return {
            mobile:window.sessionStorage.getItem("mobile"),
            arr:[
                {
                    "title":"language.conferencecenter",
                    // "title":"会议中心",
                    "text":"language.floorsandanarea",
                    // "text":"会议区共5层、面积3万平方米，根据需求设有不同规模的会议室共计30个，总计可容纳3600余人，可承接各类会议、宴会、论坛讲座、企业年会等大型室内活动。",
                        url:require('../../assets/image/组 57.png'),
                        url2:require('../../assets/image/会议中心1层前厅1.png')
                },
                {
                    "title":"language.PeopleLectureHall",
                    // "title":"千人报告厅",
                    "text":"language.framestage",
                    // "text":"12m挑高，镜框式舞台。 ",
                          url:require('../../assets/image/2层阶梯报告厅.png'),
                    
                },
                {
                    "title":"language.Onethousandpeople",
                    // "title":"千人宴会厅",
                    "text":"language.separabledesign",
                    // "text":"近2000平方米，可拆分设计，满足不同规模宴会需求。",
                        url:require('../../assets/image/1层宴会厅.png')
                   
                },
                 {
                    
                    "title":"language.Othermeetingrooms",
                    // "title":"其他会议室",
                        url:require('../../assets/image/3层中型会议室.png'),
                        url2:require('../../assets/image/洽谈室.png'),
                        url3:require('../../assets/image/组 58.png'),
                        url4:require('../../assets/image/2层贵宾厅.png'),
                   
                }
            ],
          height:"calc(100% - 70px)",
          tablelsit:[]
        }
    },
      created() {
        this.conferencecenter()
    },
    methods:{
        //   展览中心技术数据
        conferencecenter(){
            let data = {

            }
            this.$api.conferencecenter(data,res=>{
                if(res.data.code == 200){
                    this.tablelsit = res.data.data
                }
            })
        },
     
    }

}
</script>

<style scoped>
 .introduce{
        width:100%;
        background: #FAFAFA;
        
    }
    .introduce_top{
        width: 100%;
        height:41px;
        background-color:#FAFAFA;
}
    .introduce_main{
        width: 1400px;
        margin: 0 auto;
        margin-bottom: 6px;
        background: #FAFAFA;
        overflow: hidden;
    }
    .introduce_bottom{
        width: 100%;
        height: 103px;
        background-color: #FAFAFA;
    }
    .main_top{
        width: 1370px;
        background-color: #ffffff;
        padding: 33px 31px 31px 29px;
        margin-bottom: 31px;
    }
    .main_top h5{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1A1A1A;
    } 
    .main_top p{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-top: 12px;
        padding-bottom: 24px;
    }
    .main_top img{
        margin-right: 30px;
        vertical-align: auto;
    }
    .imgbot{
        display: inline-block;
        margin-top: 30px;
    }
    .introduce_tab{
        width: 1338px;
        height: 1057px;
        margin: 0 auto;
        padding: 29px 31px 54px 31px ;
        background-color: #ffffff;
    }
    .nav_p .cheched{
        background-color: #1a7cc3;
        color: white;
    }
    .tab_title p{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
        margin-bottom: 28px;
    }
    .tab_title span{
        display: inline-block;
        width: 6px;
        height: 28px;
        background: #197CC3;
        margin-right: 19px;
        line-height: 31px;
    }

.introduce_tab table{
width: 100%;
height: 974px;
text-align: center;
border-collapse: collapse;
}
.introduce_tab table th{
    background-color:#0F6FB4;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.introduce_tab table tr{
    width: 72px;
    height: 19px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
.introduce_tab table td{
    border-width: 0.5px;
   border-style: solid;
   border-top: none;
   border-bottom: none;
   border-color: #EBEBEB;
}
.thspan th{
    /* border-top:0.5px solid #EBEBEB; */
    border-right: 1px solid #EBEBEB;
}
.thspan .thspan_right{
    border-right: none;
}
.even_numbers{
    background: #F5F5F5
}
    .trs:nth-of-type(2n+1){
        background: #F5F5F5;
    }
    .trs:nth-of-type(2n){
        
        background: #fff;
    }
</style>