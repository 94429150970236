<template>
   <div class="introduce">
          <div class="introduce_top">

              </div>
          <div class="introduce_main">
              <div class="main_top" v-for="(v,i) in arr" :key="i">
                  <h5>{{$t(v.title)}}</h5>
                  <p>{{$t(v.text)}}</p>
                  <img :src="v.url" alt="">
                  <img :src="v.url2" alt="" v-if="v.url2">
              </div>
          </div>
            <div class="introduce_tab">
                <div class="tab_title">
                    <p><span></span>
                     {{$t('language.ofexhibitioncenter')}}
                    <!-- 展览中心技术数据 -->
                    </p>
                </div>
                <table class="table_one">
                    <tbody>
                        <tr>
                            <th>
                                {{$t('language.facility')}}
                                <!-- 设施 -->
                            </th>
                            <th  v-for="(item,index) in tablelsit" :key="index">
                            {{item.facilities}}
                               
                            </th>
                        </tr>
                        <tr class="trs">
                            <td>
                                {{$t('language.A1A2pavilion')}}
                                 <!-- A1、A2馆 -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                                <span v-if="index == 1">
                                    <p v-for="(i,e) in item.aPavilion.split(',')" :key="e">
                                        {{i}}
                                    </p>
                                </span>
                                <span v-else>{{item.aPavilion}}</span>
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                {{$t('language.A3A4')}}
                                 <!-- A3、A4馆 -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                                <span v-if="index == 1">
                                    <p v-for="(i,e) in item.bPavilion.split(',')" :key="e">
                                        {{i}}
                                    </p>
                                </span>
                                <span v-else>{{item.bPavilion}}</span>
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                 {{$t('language.B1B2B3')}}
                                 <!-- B1、B2、B3馆  -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                                <span v-if="index == 1">
                                    <p v-for="(i,e) in item.cPavilion.split(',')" :key="e">
                                        {{i}}
                                    </p>
                                </span>
                                <span v-else>{{item.cPavilion}}</span>
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                 {{$t('language.functionhall')}}
                               
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                                <span v-if="index == 1">
                                    <p v-for="(i,e) in item.functionHall.split(',')" :key="e">
                                        {{i}}
                                    </p>
                                </span>
                                <span v-else>{{item.functionHall}}</span>
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                {{$t('language.loginhall')}}
                                <!-- 主、次登录大厅 -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                                <span v-if="index == 1">
                                    <p v-for="(i,e) in item.loginHall.split(',')" :key="e">
                                        {{i}}
                                    </p>
                                </span>
                                <span v-else>{{item.loginHall}}</span>
                                 
                            </td>
                        </tr>
                         <!-- style="background: #4AA8EC; color:#fff;" 
                             {{$t('language.loginhall')}}
                                主、次登录大厅
                                {{$t('language.functionhall')}}
                                 多功能厅
                                 {{$t('language.B1B2B3')}}
                                 B1、B2、B3馆 
                                 {{$t('language.A3A4')}}
                                 A3、A4馆 
                                 {{$t('language.A1A2pavilion')}}
                                 A1、A2馆
                                 {{$t('language.facility')}}
                                设施
                             v-for="(item,index) in tablelsit" :key="index"
                            
                                 {{item.facilities}}
                                 {{item.aPavilion}}
                                 {{item.bPavilion}}
                                 {{item.cPavilion}}
                                 {{item.functionHall}}
                                 {{item.loginHall}} --> 
                    </tbody>
                </table>
                <div class="table_two">
                    <table>
                    <tr>
                        <th style="background: #0F6FB4; color: #fff;">
                            {{$t('language.ExhibitionHall')}}
                                 <!-- 展厅 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.Area')}}
                                 <!-- 面积 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.StoryHeight')}}
                                 <!-- 层高 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.Freightentrance')}}
                                 <!-- 货运入口 -->
                            </th>
                            <!-- <th style="background: #0F6FB4; color:#fff;"> -->
                                <!-- {{$t('language.Constructionheight')}} -->
                                 <!-- 允许搭建高度 -->
                            <!-- </th> -->
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.LoadBearing')}}
                                 <!-- 承重 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.LiftingPointNumber')}}
                                 <!-- 吊点数量/承重 -->
                            </th>
                    </tr>

    
                    <tr class="trs" v-for="(item,index) in tableTwo" :key="index">
                        <td>{{item.exhibitionHall}}</td>
                        <td>{{item.area}}m²</td>
                        <td>{{item.floorHeight}}</td>
                        <td>{{item.freightEntrance}}</td>
                        <!-- <td>6m</td> -->
                        <td>{{item.bearing}}t/㎡</td>
                        <td>{{item.quantity}}</td>
                    </tr>
                    
                </table>
                </div>
            </div>
            <div class="introduce_bottom">

            </div>
        </div>
</template>

<script>
export default {
data(){
    return{
           arr:[
                {
                    "title":"language.Venueintroduction",
                    // "title":"场地简介",
                    "text":"language.Exhibitiongalleriesforindoor",
                    // "text":"展览区分为室内展区和室外展区，室内共设有8个展厅，室内展览面积6.6万平方米，总展位3299个，由双首层五加三展厅的标准单元构成，利用场地自然高差巧妙地使8个展厅都能实现地面平进，每个展厅均拥有独立的物流及人流通道；室外展厅面积约3.3万平方米，可为各类会展活动提供展览空间。",
                        url:require('../../assets/image/20210304烟台八角湾会展中心方案文本 (3)_页面_058.png')
                },
                {
                    "title":"language.Logontothehall",
                    // "title":"登录大厅",
                    "text":"language.Thedesignstyleoftheentry",
                    // "text":"登录厅设计风格与外观建筑和谐统一，高挑的室内空间和简约的线条勾勒，让人仿佛置身云海。“繁华虽少减，高雅亦足奇”，大面积超白玻璃幕墙充分的将自然光线引入进来，营造出素雅大气之感。",
                          url:require('../../assets/image/登录厅1.png'),
                        url2:require('../../assets/image/次登陆厅1.png'),
                    
                },
                {
                    "title":"language.Themulti",
                    // "title":"多功能厅",
                    "text":"language.Thelargepillarfunctional",
                    // "text":"4000平米超大无柱多功能厅，简约不失奢华，大气又显精致。适合举办大型会议和宴会、企业年会、各种定制活动。",
                            url:require('../../assets/image/空场.png'),
                        url2:require('../../assets/image/排桌.png'),
                   
                }
            ],
          
        height:"calc(100% - 70px)",
        tablelsit:[],
        tableTwo:[]
        }
    },
        created() {
        this.exhibitioncenter()
        this.exhibitionHallcenter()
    },
    methods:{
        //   展览中心技术数据
        exhibitioncenter(){
            let data = {

            }
            this.$api.exhibitioncenter(data,res=>{
                if(res.data.code == 200){
                   let tablelsit = res.data.data.map((item) => {
                       return item;
                    })
                    this.tablelsit = tablelsit   
                }
            })
        },
     //   展管技术数据
        exhibitionHallcenter(){
            let data = {

            }
            this.$api.exhibitionHallcenter(data,res=>{
                if(res.data.code == 200){
                    this.tableTwo = res.data.data   
                }
            })
        },
    }
    
}
</script>

<style scoped>
       .introduce{
        width:100%;
        background: #FAFAFA;
        
    }
    .introduce_top{
        width: 100%;
        height:41px;
        background-color:#FAFAFA;
}
.introduce_bottom{
        width: 100%;
        height: 103px;
        background-color: #FAFAFA;
    }
    .introduce_main{
        width: 1400px;
        margin: 0 auto;
        margin-bottom: 30px;
        background: #FAFAFA;
        overflow: hidden;
    }
    .main_top{
        width: 1370px;
        background-color: #ffffff;
        padding: 33px 31px 31px 29px;
        margin-bottom: 31px;
    }
    .main_top h5{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1A1A1A;
    } 
    .main_top p{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-top: 12px;
        padding-bottom: 24px;
    }
    .main_top img{
        margin-right: 30px;
        vertical-align: auto;
    }
    .introduce_tab{
        width: 1338px;
        height: 1281px;
        margin: 0 auto;
        padding: 29px 31px 54px 31px ;
        background-color: #ffffff;
    }
    .cheched{
        background-color: #1a7cc3;
        color: white;
    }
    .tab_title p{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
        margin-bottom: 28px;
    }
    .tab_title span{
        display: inline-block;
        width: 6px;
        height: 28px;
        background: #197CC3;
        margin-right: 19px;
        line-height: 31px;
    }

.introduce_tab .table_one{
width: 100%;
height: 400px;
text-align: center;
border-collapse: collapse;
}
.table_two{
width: 100%;
height: 600px;
text-align: center;
border-collapse: collapse;
margin-top: 52px;
margin-bottom: 55px;
}
.table_two table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}
 .table_two table tr{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333; 
}
.table_two th{
    background-color:#0F6FB4;
    border: 0.5px solid #EBEBEB;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
 .table_two table tr td{
     font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    border-width: 0.5px;
   border-style: solid;
   border-top: none;
   border-bottom: none;
   border-color: #EBEBEB;
 }
.introduce_tab .table_two td{
     border-width: 0.5px;
   border-style: solid;
   border-color: #EBEBEB;
}
.introduce_tab .table_one th{
    background-color:#0F6FB4;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    padding-left: 10px;
    padding-right: 10px
}
.introduce_tab .table_one tr{
    width: 72px;
    height: 66px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
.introduce_tab .table_one td{
    padding-left: 10px;
    padding-right: 10px;
    border-width: 0.5px;
   border-style: solid;
    color: #333333;
   border-color: #EBEBEB;
}
.even_numbers{
    background: #F5F5F5
}
    .trs:nth-of-type(2n+1){
        background: #F5F5F5;
    }
    .trs:nth-of-type(2n){
        
        background: #fff;
    }
</style>