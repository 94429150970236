var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"traffic"},[_c('div',{staticClass:"nav"},[_c('Carousel',{attrs:{"show":"1"}})],1),_c('div',{staticClass:"navs"},[_c('div',{staticClass:"navcon"},[_c('p',{staticClass:"nav_p"},[_c('span',{style:({
            background: _vm.show == 'exhibition' ? '#197CC3' : '#fff',
            color: _vm.show == 'exhibition' ? '#fff' : '#000',
            zIndex: _vm.show == 'exhibition' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('exhibition', $event, 'ExhibitionHallSurvey')}}},[_vm._v(_vm._s(_vm.$t("language.ExhibitionHallSurvey"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'site' ? '#197CC3' : '#fff',
            color: _vm.show == 'site' ? '#fff' : '#000',
            zIndex: _vm.show == 'site' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('site', $event, 'exhibitionGround')}}},[_vm._v(_vm._s(_vm.$t("language.exhibitionGround"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'conference' ? '#197CC3' : '#fff',
            color: _vm.show == 'conference' ? '#fff' : '#000',
            zIndex: _vm.show == 'conference' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('conference', $event, 'ConferenceCenter')}}},[_vm._v(_vm._s(_vm.$t("language.ConferenceCenter"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'survey' ? '#197CC3' : '#fff',
            color: _vm.show == 'survey' ? '#fff' : '#000',
            zIndex: _vm.show == 'survey' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('survey', $event, 'ExquisiteGroupPictures')}}},[_vm._v(_vm._s(_vm.$t("language.ExquisiteGroupPictures")))])]),_c('p',[_c('van-icon',{attrs:{"color":"#999999","name":"wap-home","size":"20"}}),_c('span',[_vm._v(_vm._s(_vm.$t("language.current"))+" : "+_vm._s(_vm.$t("language.HOMEpage"))+" > "),_c('span',{staticClass:"spans",domProps:{"innerHTML":_vm._s(_vm.$t('language.PavilionGuide'))}}),_vm._v(" > "+_vm._s(_vm.$t(_vm.title))+" ")])],1)])]),(_vm.show == 'exhibition')?_c('div',{staticClass:"content"},[_c('Exhibition')],1):(_vm.show == 'site')?_c('div',{staticClass:"content"},[_c('Site')],1):(_vm.show == 'conference')?_c('div',{staticClass:"content"},[_c('Conference')],1):(_vm.show == 'survey')?_c('div',{staticClass:"content"},[_c('Survey')],1):_vm._e(),_c('div',{staticClass:"bot"},[_c('Bottom')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }